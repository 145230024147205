import React from 'react';
import ReactGA from 'react-ga';

import { isProduction } from 'config/env';
import Router from 'components/Router';

if (isProduction) ReactGA.initialize('UA-37961122-29');

function App() {
  return <Router />;
}

export default App;
