import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { useMachine } from '@xstate/react';

import client from 'config/apollo';
import { machine } from 'lib';
import { useDocumentTitle } from 'lib/hooks';
import { sendEvent } from 'lib/utils';
import Content from 'components/Content';
import Layout from 'components/Layout';
import RecommendationProvider from 'components/RecommendationProvider';
import Search from 'components/Search';

function Main() {
  const [current, send] = useMachine(machine);
  const location = useLocation();
  useDocumentTitle('Should I Use?');

  const sendSelectionEvent = selection => {
    sendEvent('FS', {
      eventName: 'Selected Package',
      eventProperties: {
        package: selection,
      },
    });
    sendEvent('GA', {
      category: 'Recommendation',
      action: 'Selected a Package',
      label: selection,
    });
  };

  useEffect(() => {
    const search = location.search;
    if (search) {
      const queryParam = search.split('=').pop();
      if (queryParam) {
        send('SELECT', { data: queryParam });
        sendSelectionEvent(queryParam);
      }
    }
  }, [send, location]);

  function handleSelect(selection) {
    send('SELECT', { data: selection });
    sendSelectionEvent(selection);
  }

  return (
    <ApolloProvider client={client}>
      <RecommendationProvider recommendation={current.context.recommendation}>
        <Layout>
          <Search
            onChange={event => send('TYPE', { query: event.target.value })}
            onSelect={handleSelect}
            onClear={() => send('CLEAR')}
            context={current.context}
          />
          <Content current={current} send={send} />
        </Layout>
      </RecommendationProvider>
    </ApolloProvider>
  );
}

export default Main;
