import { Machine, assign } from 'xstate';

import { actions, services } from 'lib';

const initialContext = {
  query: '',
  error: '',
  results: [],
  loadingText: '',
  data: null,
  recommendation: null,
  criteria: null,
};

const machine = Machine(
  {
    id: 'machine',
    initial: 'idle',
    context: initialContext,
    states: {
      idle: {
        on: {
          TYPE: {
            target: 'searching',
            actions: ['type'],
          },
          SELECT: {
            target: '#recommend',
            actions: ['select', 'setUrlParams'],
          },
          SELECT_MULTIPLE: {
            target: '#recommend',
            actions: ['select'],
          },
        },
      },
      searching: {
        id: 'searching',
        initial: 'loading',
        states: {
          loading: {
            entry: assign({
              loadingText: 'Searching NPM...',
            }),
            invoke: {
              id: 'search',
              src: services.search,
              onDone: [
                {
                  cond: 'canSearch',
                  target: 'success',
                  actions: assign({
                    results: (_, event) => event.data,
                  }),
                },
                {
                  target: '#machine.idle',
                  actions: ['clear'],
                },
              ],
              onError: {
                target: 'error',
                actions: assign({
                  error: (_, event) => event.data,
                  results: [],
                }),
              },
            },
            on: {
              TYPE: [
                {
                  cond: 'canSearch',
                  target: 'loading',
                  actions: ['type'],
                },
                {
                  target: '#machine.idle',
                  actions: ['clear'],
                },
              ],
              BLUR: {
                target: '#machine.idle',
                actions: ['clearResults'],
              },
            },
          },
          error: {
            on: {
              CLEAR: {
                target: '#machine.idle',
                actions: ['clear'],
              },
              BLUR: {
                target: '#machine.idle',
                actions: ['clearResults'],
              },
              TYPE: {
                target: 'loading',
                actions: ['type'],
              },
            },
          },
          success: {
            on: {
              SELECT: {
                target: '#recommend',
                actions: ['select', 'setUrlParams'],
              },
              CLEAR: {
                target: '#machine.idle',
                actions: ['clear'],
              },
              BLUR: {
                target: '#machine.idle',
                actions: ['clearResults'],
              },
              TYPE: {
                target: 'loading',
                actions: ['type'],
              },
            },
          },
        },
      },
      recommend: {
        id: 'recommend',
        initial: 'step1',
        states: {
          step1: {
            invoke: {
              id: 'step1',
              src: services.getNPMData,
              onDone: {
                target: 'step2',
                actions: assign({
                  data: (context, event) => ({
                    ...context.data,
                    ...event.data,
                  }),
                  loadingText: 'Getting GitHub repo data...',
                }),
              },
              onError: {
                target: 'error',
                actions: assign({
                  error: (_, event) => event.data,
                }),
              },
            },
          },
          step2: {
            invoke: {
              id: 'step2',
              src: services.getGitHubRepoInfo,
              onDone: {
                target: 'step3',
                actions: assign({
                  data: (context, event) => ({
                    ...context.data,
                    ...event.data,
                  }),
                  loadingText: 'Getting GitHub repo data...',
                }),
              },
              onError: {
                target: 'error',
                actions: assign({
                  error: (_, event) => event.data,
                }),
              },
            },
          },
          step3: {
            invoke: {
              id: 'step3',
              src: services.getGitHubMainData,
              onDone: {
                target: 'success',
                actions: assign({
                  data: (context, event) => ({
                    ...context.data,
                    ...event.data,
                  }),
                }),
              },
              onError: {
                target: 'error',
                actions: assign({
                  error: (_, event) => event.data,
                  data: null,
                }),
              },
            },
          },
          error: {
            on: {
              TYPE: {
                target: '#searching',
                actions: ['type'],
              },
              CLEAR: {
                target: '#machine.idle',
                actions: ['clear'],
              },
            },
          },
          success: {
            entry: ['setRecommendation'],
            on: {
              UPDATE: {
                target: '#recommend.success',
                actions: assign({
                  criteria: (_, event) => event.criteria,
                }),
              },
              TYPE: {
                target: '#searching',
                actions: ['type'],
              },
              CLEAR: {
                target: '#machine.idle',
                actions: ['clear'],
              },
            },
          },
        },
      },
    },
  },
  {
    actions: actions(initialContext),
    guards: {
      canSearch: context => context.query && context.query.length > 0,
    },
  }
);

export default machine;
