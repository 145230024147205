import get from 'lodash/get';
import { isBefore } from 'date-fns';

import isVersionGreaterOrEqualTo from './isVersionGreaterOrEqualTo';

function getLatestRelease({ release, tag, version }) {
  const { name: tagName, date: tagDate } = tag;
  let _date = null;
  const releaseDate = get(release, 'publishedAt');

  if (releaseDate) {
    const isReleaseDateBeforeTagDate = isBefore(
      new Date(releaseDate),
      new Date(tagDate)
    );
    _date =
      isReleaseDateBeforeTagDate && isVersionGreaterOrEqualTo(tagName, version)
        ? new Date(tagDate)
        : new Date(releaseDate);
  }

  return _date;
}

export default getLatestRelease;
