import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'react-error-boundary';

import About from 'components/About';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Recommendation from 'components/Recommendation';

function Content(props = {}) {
  const { current } = props;

  if (
    current.matches('searching.error') ||
    current.matches('recommend.error')
  ) {
    return <p className="U-ta-center">{current.context.error}</p>;
  }

  const loading =
    current.matches('searching.loading') ||
    current.matches('recommend.step1') ||
    current.matches('recommend.step2') ||
    current.matches('recommend.step3');

  if (loading && props.showLoader) {
    return <Loader text={current.context.loadingText} />;
  }

  if (current.matches('recommend.success') && props.showContent) {
    return (
      <ErrorBoundary>
        <Recommendation
          collapsedByDefault={props.showCollapsedContent}
          collapsible={props.collapsible}
        />
      </ErrorBoundary>
    );
  }

  if (!props.showIdle) return null;

  return (
    <>
      <About />
      <Footer />
    </>
  );
}

Content.propTypes = {
  collapsible: PropTypes.bool,
  current: PropTypes.object,
  send: PropTypes.func,
  showCollapsedContent: PropTypes.bool,
  showContent: PropTypes.bool,
  showIdle: PropTypes.bool,
  showLoader: PropTypes.bool,
};

Content.defaultProps = {
  showCollapsedContent: false,
  showContent: true,
  showIdle: true,
  showLoader: true,
};

export default Content;
