import { getRatio, isPastXMonths, isPrerelease, parseVersion } from '../utils';

export const VERSION = {
  1: {
    yes: 'At least 1.0.0',
    no: {
      1: 'Post-1.0.0, but a pre-release',
      2: 'Less than 1.0.0',
    },
  },
};
export const LATEST_RELEASE = {
  1: {
    yes: 'In the past six months',
    no: 'Greater than six months ago',
  },
};
export const LATEST_COMMIT = {
  1: {
    yes: 'In the past six months',
    no: 'Greater than six months ago',
  },
};
export const WEEKLY_DOWNLOADS = {
  1: {
    yes: 'More than 100',
    no: 'Less than 100',
  },
};
export const CONTRIBUTOR_COUNT = {
  1: {
    yes: 'More than 10',
    no: 'Less than 10',
  },
};
export const DEPENDENCIES = {
  1: {
    yes: 'Less than 40',
    no: 'More than 40',
  },
};
export const ISSUES_AND_PULLS_RATIO = {
  1: {
    yes: 'Ratio less than 2',
    no: 'Ratio more than 2',
  },
};

export const initialCriteria = {
  version: VERSION[1].yes,
  latestRelease: LATEST_RELEASE[1].yes,
  latestCommit: LATEST_COMMIT[1].yes,
  weeklyDownloads: WEEKLY_DOWNLOADS[1].yes,
  contributorCount: CONTRIBUTOR_COUNT[1].yes,
  dependencies: DEPENDENCIES[1].yes,
  issuesAndPullsRatio: ISSUES_AND_PULLS_RATIO[1].yes,
};

export const getRecommendation = (recommended, reason) => ({
  recommended,
  reason,
});

export function version({ value: version, matcher }) {
  switch (matcher) {
    case VERSION[1].yes: {
      const isVersion1 = parseVersion(version) >= 1;
      if (isVersion1 && !isPrerelease(version)) {
        return getRecommendation(true, VERSION[1].yes);
      }
      if (isVersion1 && isPrerelease) {
        return getRecommendation(false, VERSION[1].no[1]);
      }
      return getRecommendation(false, VERSION[1].no[2]);
    }
    default: {
      return getRecommendation(false, VERSION[1].no[2]);
    }
  }
}

export function latestRelease({ value: date, matcher }) {
  const _date = new Date(date);
  switch (matcher) {
    case LATEST_RELEASE[1].yes: {
      if (isPastXMonths(_date, 6)) {
        return getRecommendation(true, LATEST_RELEASE[1].yes);
      }
      return getRecommendation(false, LATEST_RELEASE[1].no);
    }
    default: {
      return getRecommendation(false, LATEST_RELEASE[1].no);
    }
  }
}

export function latestCommit({ value: date, matcher }) {
  const _date = new Date(date);
  switch (matcher) {
    case LATEST_COMMIT[1].yes: {
      if (isPastXMonths(_date, 6)) {
        return getRecommendation(true, LATEST_COMMIT[1].yes);
      }
      return getRecommendation(false, LATEST_COMMIT[1].no);
    }
    default: {
      return getRecommendation(false, LATEST_COMMIT[1].no);
    }
  }
}

export function weeklyDownloads({ value: downloads, matcher }) {
  switch (matcher) {
    case WEEKLY_DOWNLOADS[1].yes: {
      if (downloads > 100) {
        return getRecommendation(true, WEEKLY_DOWNLOADS[1].yes);
      }
      return getRecommendation(false, WEEKLY_DOWNLOADS[1].no);
    }
    default: {
      return getRecommendation(false, WEEKLY_DOWNLOADS[1].no);
    }
  }
}

export function contributorCount({ value: count, matcher }) {
  switch (matcher) {
    case CONTRIBUTOR_COUNT[1].yes: {
      if (count > 10) {
        return getRecommendation(true, CONTRIBUTOR_COUNT[1].yes);
      }
      return getRecommendation(false, CONTRIBUTOR_COUNT[1].no);
    }
    default: {
      return getRecommendation(false, CONTRIBUTOR_COUNT[1].no);
    }
  }
}

export function dependencies({ value: count, matcher }) {
  switch (matcher) {
    case DEPENDENCIES[1].yes: {
      if (count < 40) {
        return getRecommendation(true, DEPENDENCIES[1].yes);
      }
      return getRecommendation(false, DEPENDENCIES[1].no);
    }
    default: {
      return getRecommendation(false, DEPENDENCIES[1].no);
    }
  }
}

export function issuesAndPullsRatio({ value: data, matcher }) {
  const { openedIssues, closedIssues, openedPulls, mergedPulls } = data;
  if (
    openedIssues === 0 &&
    closedIssues === 0 &&
    openedPulls === 0 &&
    mergedPulls === 0
  ) {
    return getRecommendation(false, 'Not enough data');
  }
  const issuesRatio = getRatio(openedIssues, closedIssues);
  const pullsRatio = getRatio(openedPulls, mergedPulls);
  switch (matcher) {
    case ISSUES_AND_PULLS_RATIO[1].yes: {
      if (issuesRatio < 2 || pullsRatio < 2) {
        return getRecommendation(true, ISSUES_AND_PULLS_RATIO[1].yes);
      }
      return getRecommendation(false, ISSUES_AND_PULLS_RATIO[1].no);
    }
    default: {
      return getRecommendation(false, ISSUES_AND_PULLS_RATIO[1].no);
    }
  }
}
