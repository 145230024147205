import React from 'react';
import PropTypes from 'prop-types';

function Loader(props = {}) {
  return (
    <div className="Loader">
      <div className="Loader-bar"></div>
      <p className="Loader-text">{props.text}</p>
    </div>
  );
}

Loader.propTypes = {
  text: PropTypes.string,
};

Loader.defaultProps = {
  text: 'Loading...',
};

export default Loader;
