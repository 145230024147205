import { useCallback, useReducer } from 'react';

const initialState = {
  complete: false,
  completionCount: 0,
  loadingText: 'Running report...',
  packages: [],
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'SET_PACKAGES': {
      return {
        ...state,
        complete: false,
        packages: payload,
      };
    }
    case 'COMPLETE': {
      const isAllComplete = state.completionCount + 1 === state.packages.length;

      if (!isAllComplete) {
        return {
          ...state,
          loadingText: payload,
          completionCount: state.completionCount + 1,
        };
      }

      return {
        ...initialState,
        complete: true,
        loadingText: '',
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function useReport(packages) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setPackages = useCallback(packages => {
    dispatch({
      type: 'SET_PACKAGES',
      payload: packages,
    });
  }, []);

  const handleComplete = useCallback(pkg => {
    dispatch({
      type: 'COMPLETE',
      payload: `${pkg} recommendation is complete...`,
    });
  }, []);

  return [state, { handleComplete, setPackages }];
}

export default useReport;
