import get from 'lodash/get';

import * as types from 'config/types';
import {
  cleanData,
  getDescription,
  getGHRepoUrl,
  getLatestRelease,
  getLatestVersion,
  getPackageUrl,
  getRatio,
  isPastXMonths,
  parseGHData,
  parseNPMUrl,
} from './';

const parseFullData = ({ repo, owner, npmData, ghData, contributors }) => {
  const { issues, pulls, release, commit, tag } = parseGHData(ghData);
  const numberOfOpenedIssues = !issues
    ? null
    : issues?.filter(issue => isPastXMonths(issue['createdAt'], 1)).length;
  const numberOfClosedIssues = !issues
    ? null
    : issues?.filter(issue => isPastXMonths(issue['closedAt'], 1)).length;
  const numberOfOpenedPulls = !pulls
    ? null
    : pulls?.filter(pull => isPastXMonths(pull['createdAt'], 1)).length;
  const numberOfMergedPulls = !pulls
    ? null
    : pulls?.filter(pull => isPastXMonths(pull['mergedAt'], 1)).length;
  const ghLink = parseNPMUrl(npmData);
  const rootUrl = getPackageUrl({ repo, owner, npmData });
  const ghUrl = getGHRepoUrl(ghLink);
  const url = ghUrl ? ghUrl.url : rootUrl;
  const version = getLatestVersion(npmData, tag);
  const pkg = get(npmData, 'collected.metadata.name', repo);
  const description = getDescription(npmData);

  const parsedData = {
    [types.NAME]: pkg,
    [types.DESCRIPTION]: description,
    [types.VERSION]: version,
    [types.REPO]: repo,
    [types.LINKS]: {
      root: url,
      [types.GITHUB]: get(ghUrl, 'url', null),
      [types.NPM]: get(npmData, 'collected.metadata.links.npm'),
      [types.BUNDLEPHOBIA]: `https://bundlephobia.com/result?p=${pkg}`,
    },
    [types.WEEKLY_DOWNLOADS]: get(npmData, 'collected.npm.downloads[1].count'),
    [types.LATEST_RELEASE]: getLatestRelease({ release, tag, version }),
    [types.LATEST_COMMIT]: new Date(get(commit, 'committedDate')),
    [types.CONTRIBUTOR_COUNT]: contributors?.length,
    [types.DEPENDENCIES]: get(npmData, 'collected.metadata.dependencies')
      ? Object.keys(get(npmData, 'collected.metadata.dependencies')).length
      : null,
    [types.ISSUES_AND_PULLS_RATIO]: {
      [types.OPENED_ISSUES]: numberOfOpenedIssues,
      [types.CLOSED_ISSUES]: numberOfClosedIssues,
      [types.OPENED_PULLS]: numberOfOpenedPulls,
      [types.MERGED_PULLS]: numberOfMergedPulls,
      [types.ISSUES_RATIO]: getRatio(
        numberOfOpenedIssues,
        numberOfClosedIssues
      ),
      [types.PULLS_RATIO]: getRatio(numberOfOpenedPulls, numberOfMergedPulls),
    },
  };

  const parsedAndCleanedData = cleanData(parsedData);
  return parsedAndCleanedData;
};

export default parseFullData;
