import * as Sentry from '@sentry/browser';

function initializeSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV || 'development',
  });
}

export default initializeSentry;
