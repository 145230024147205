import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import DiLogo from 'components/DiLogo';

function Layout(props = {}) {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const isNotHomePage = location.pathname !== '/';
  const title = 'Should I Use?';

  useEffect(() => {
    if (!loaded) setLoaded(true);
  }, [loaded, setLoaded]);

  return (
    <div className={`App${loaded ? ' is-loaded' : ''}`}>
      <h1 className="Title App-title" data-testid="App-title">
        {isNotHomePage ? <Link to="/">{title}</Link> : title}
      </h1>
      <DiLogo />
      <main className="Content App-content">{props.children}</main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default Layout;
