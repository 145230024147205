const generateClassNames = (root, keys) => {
  let _keys = [];
  keys.forEach(key => (_keys[key] = `${root}-${key}`));
  return {
    root,
    ..._keys,
  };
};

export default generateClassNames;
