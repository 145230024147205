import get from 'lodash/get';

import client from 'config/apollo';
import {
  fancyFetch,
  handleResponseErrors,
  GITHUB_QUERY,
  GITHUB_BASE_BRANCH_QUERY,
  GITHUB_REPO_INFO_QUERY,
  parseNPMData,
  parseGHBranch,
} from 'lib/utils';
import isNil from 'lodash/isNil';

const NPM_REGISTRY_URL = 'https://api.npms.io/v2/package';
const GITHUB_API_URL = 'https://api.github.com';

export async function search(context) {
  const { query } = context;
  // TODO: This should be a better guard in the machine.
  if (query === '') return null;
  try {
    const data = await fancyFetch(
      `https://api.npms.io/v2/search/suggestions?q=${query}&size=10`
    );
    return data;
  } catch (error) {
    const message = handleResponseErrors(error, { pkg: query });
    throw message;
  }
}

export async function _fetch(url) {
  let options = {};
  if (url.match(/github/i))
    options = {
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_GITHUB_API_TOKEN}`,
      },
    };
  return fancyFetch(url, options);
}

export async function getNPMData(context) {
  try {
    const { query } = context;
    const encodedQuery = encodeURIComponent(query);
    const npmData = await _fetch(`${NPM_REGISTRY_URL}/${encodedQuery}`);
    const repoInfo = parseNPMData(npmData);
    return { npmData, repoInfo };
  } catch (error) {
    const message = handleResponseErrors(error, { pkg: context.query });
    throw message;
  }
}

export async function getGitHubRepoInfo(context) {
  try {
    const pkg = context.query;
    let query = pkg;
    const match = pkg.match(/.*\/(.*)/i);

    if (match) {
      const [, parsedPkg] = match;
      query = parsedPkg;
    }

    const ghRepoInfo = await client.query({
      query: GITHUB_REPO_INFO_QUERY,
      variables: {
        query,
      },
      fetchPolicy: 'no-cache',
    });

    const nameWithOwner = get(ghRepoInfo, 'data.search.nodes[0].nameWithOwner');
    const [owner, repo] = nameWithOwner?.split('/');
    const repoInfo = { repo, owner };

    return { repoInfo };
  } catch (error) {
    const message = handleResponseErrors(error, { pkg: context.query });
    throw message;
  }
}

export async function getGitHubMainData(context) {
  try {
    const { repo, owner } = get(context, 'data.repoInfo');
    if (isNil(repo) || isNil(owner)) {
      throw new Error('No repo or owner information');
    }
    const ghQueryVariables = {
      name: repo,
      owner,
    };
    const ghBaseBranch = await client.query({
      query: GITHUB_BASE_BRANCH_QUERY,
      variables: ghQueryVariables,
    });
    const ghData = await client.query({
      query: GITHUB_QUERY,
      variables: {
        ...ghQueryVariables,
        branch: parseGHBranch(ghBaseBranch),
      },
      fetchPolicy: 'no-cache',
    });
    const contributors = await _fetch(
      `${GITHUB_API_URL}/repos/${owner}/${repo}/contributors?per_page=100`
    );
    return { repo, owner, ghData, contributors };
  } catch (error) {
    const message = handleResponseErrors(error, { pkg: context.query });
    throw message;
  }
}
