import ReactGA from 'react-ga';
import get from 'lodash/get';

import { isProduction, isTest } from 'config/env';

function sendEvent(service, options = {}) {
  if (!isProduction && !isTest) {
    console.log({ service, options });
    return;
  }

  switch (service) {
    case 'FS': {
      const FS = get(window, 'FS');
      if (!FS) return;
      const { eventName, eventProperties } = options;
      FS.event(eventName, eventProperties);
      break;
    }
    case 'GA': {
      ReactGA.event(options);
      break;
    }
    default: {
      return null;
    }
  }
}

export default sendEvent;
