import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import Downshift from 'downshift';
import { send } from 'xstate';

import { generateClassNames } from 'lib/utils';

function Search(props = {}) {
  const inputRef = useRef(null);
  const classNames = generateClassNames('Search', [
    'form',
    'input',
    'results',
    'clear',
  ]);
  const context = props.context;
  const results = get(context, 'results', []);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleClose = downshift => event => {
    event.preventDefault();
    props.onClear && props.onClear();
    inputRef.current.focus();
  };

  return (
    <Downshift
      inputValue={context.query}
      itemToString={item => (item ? get(item, 'package.name') : '')}
      onChange={selection => {
        const pkg = get(selection, 'package.name');
        props.onSelect(pkg);
      }}
      defaultHighlightedIndex={0}
    >
      {downshift => (
        <div className={classNames.root}>
          <form
            {...downshift.getRootProps(
              { className: classNames.form },
              { suppressRefError: true }
            )}
          >
            <div className="U-p-relative">
              <input
                {...downshift.getInputProps({
                  onBlur: () => send('BLUR'),
                  onChange: throttle(props.onChange, 500),
                  className: classNames.input,
                  placeholder: 'Enter a package...',
                  ref: inputRef,
                })}
                spellCheck="false"
                data-testid="Search-input"
              />
              {downshift.inputValue !== '' ? (
                <button
                  className={classNames.clear}
                  onClick={handleClose(downshift)}
                  data-testid="Search-clear"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6L6 18M6 6l12 12" />
                  </svg>
                </button>
              ) : null}
            </div>
            <p className={`${classNames.input}-description`}>
              <span className="U-mr-xs">
                Uses the <a href="https://developer.github.com/">GitHub</a> and{' '}
                <a href="https://npms.io/">npms</a> APIs.
              </span>
              <Link to="/report">Upload a package.json</Link>
            </p>
          </form>
          {results.length !== 0 && downshift.isOpen ? (
            <ul
              {...downshift.getMenuProps({
                className: classNames.results,
              })}
            >
              {results.map((result, idx) => (
                <li
                  key={idx}
                  {...downshift.getItemProps({
                    className:
                      downshift.highlightedIndex === idx
                        ? `${classNames.results}-item ${classNames.results}-item--active`
                        : `${classNames.results}-item`,
                    key: get(result, 'package.name'),
                    index: idx,
                    item: result,
                  })}
                >
                  <b className={`${classNames.results}-item-name`}>
                    {get(result, 'package.name')}
                  </b>
                  <span className={`${classNames.results}-item-description`}>
                    {get(result, 'package.description', 'No description.')}
                  </span>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

Search.propTypes = {
  context: PropTypes.object,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  selector: PropTypes.object,
};

export default Search;
