import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props = {}) {
  return (
    <div className="Footer" data-testid="Footer">
      <p>
        Created by <a href="https://drewbarontini.com">Drew Barontini</a>
        <span className="U-c-subdued U-mx-s">/</span>
        Supported by <a href="https://differential.com">Differential</a>
        <span className="U-c-subdued U-mx-s">/</span>
        <Link to="/contact">Contact us</Link>
      </p>
    </div>
  );
}

export default Footer;
