import get from 'lodash/get';

import * as types from 'config/types';
import parseFullData from '../utils/parseFullData';
import * as _criteria from './criteria';

class Recommendation {
  constructor(criteria) {
    this.setCriteria(criteria);
    this.criteria.matchers = _criteria.initialCriteria;
    this.recommendations = {};
  }

  setCriteria(criteria) {
    if (criteria) {
      this.criteria = criteria;
    } else {
      this.criteria = {
        [types.VERSION]: _criteria.version,
        [types.LATEST_RELEASE]: _criteria.latestRelease,
        [types.LATEST_COMMIT]: _criteria.latestCommit,
        [types.WEEKLY_DOWNLOADS]: _criteria.weeklyDownloads,
        [types.CONTRIBUTOR_COUNT]: _criteria.contributorCount,
        [types.DEPENDENCIES]: _criteria.dependencies,
        [types.ISSUES_AND_PULLS_RATIO]: _criteria.issuesAndPullsRatio,
      };
    }
  }

  update(matchers) {
    this.criteria.matchers = {
      ...this.criteria.matchers,
      ...matchers,
    };
  }

  normalizeData(data) {
    this.data = parseFullData(data);
    return this.data;
  }

  match({ key, value }) {
    if (!value) return _criteria.getRecommendation(null, 'No data present');
    if (this.criteria[key]) {
      return this.criteria[key]({
        value,
        matcher: this.criteria.matchers[key],
      });
    }

    throw new Error(`${key} does not have a function in setCriteria().`);
  }

  get recommended() {
    if (!this.recommendations) return null;
    return Object.values(this.recommendations).every(
      v => v.recommended === true || v.recommended === null
    );
  }

  getRecommendation() {
    Object.values(types.criteria).forEach(type => {
      this.recommendations[type] = this.match({
        key: type,
        value: get(this.data, type),
      });
    });
    return {
      recommendations: this.recommendations,
      recommended: this.recommended,
    };
  }
}

export default Recommendation;
