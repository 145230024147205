import normalizeVersion from './normalizeVersion';

function isVersionGreaterOrEqualTo(v1, v2) {
  const _v1 = normalizeVersion(v1);
  const _v2 = normalizeVersion(v2);

  if (!_v1 || !_v2) return null;
  if (_v1 === _v2) return true;

  const [v1Major, v1Minor, v1Patch] = _v1.split('.');
  const [v2Major, v2Minor, v2Patch] = _v2.split('.');

  if (parseInt(v1Major) < parseInt(v2Major)) return false;
  if (
    parseInt(v1Major) === parseInt(v2Major) &&
    parseInt(v1Minor) < parseInt(v2Minor)
  )
    return false;
  if (
    parseInt(v1Major) === parseInt(v2Major) &&
    parseInt(v1Minor) === parseInt(v2Minor) &&
    parseInt(v1Patch) < parseInt(v2Patch)
  )
    return false;
  return true;
}

export default isVersionGreaterOrEqualTo;
