import get from 'lodash/get';

function normalizeVersion(version) {
  const match = String(version).match(/\d+\.\d+.\d+.*/g);
  if (!match) return null;
  if (get(match, '[0]')) return match[0];
  return match;
}

export default normalizeVersion;
