export const NAME = 'name';
export const DESCRIPTION = 'description';
export const VERSION = 'version';
export const REPO = 'repo';
export const LINKS = 'links';
export const GITHUB = 'github';
export const NPM = 'npm';
export const BUNDLEPHOBIA = 'bundlephobia';
export const WEEKLY_DOWNLOADS = 'weeklyDownloads';
export const LATEST_RELEASE = 'latestRelease';
export const LATEST_COMMIT = 'latestCommit';
export const CONTRIBUTOR_COUNT = 'contributorCount';
export const DEPENDENCIES = 'dependencies';
export const ISSUES_AND_PULLS_RATIO = 'issuesAndPullsRatio';
export const OPENED_ISSUES = 'openedIssues';
export const CLOSED_ISSUES = 'closedIssues';
export const OPENED_PULLS = 'openedPulls';
export const MERGED_PULLS = 'mergedPulls';
export const ISSUES_RATIO = 'issuesRatio';
export const PULLS_RATIO = 'pullsRatio';

export const criteria = [
  VERSION,
  LATEST_RELEASE,
  LATEST_COMMIT,
  WEEKLY_DOWNLOADS,
  CONTRIBUTOR_COUNT,
  DEPENDENCIES,
  ISSUES_AND_PULLS_RATIO,
];
