import React from 'react';

import { generateClassNames } from 'lib/utils';
import { useDocumentTitle } from 'lib/hooks';
import Layout from 'components/Layout';

function Contact(props = {}) {
  const classNames = generateClassNames('Form', ['field', 'label', 'input']);
  useDocumentTitle('Contact - Should I Use?');

  return (
    <Layout>
      <h2 className="PageSubtitle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="U-c-primary U-mr-s"
        >
          <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
        </svg>
        Get in touch. We&apos;d love to hear from you.
      </h2>
      <form
        name="contact"
        method="POST"
        action="contact/success"
        data-netlify="true"
        className={classNames.root}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p className={classNames.field}>
          <label className={classNames.label} htmlFor="name">
            Name
          </label>
          <input
            className={classNames.input}
            type="text"
            name="name"
            id="name"
            required
          />
        </p>
        <p className={classNames.field}>
          <label className={classNames.label} htmlFor="email">
            Email
          </label>
          <input
            className={classNames.input}
            type="email"
            name="email"
            id="email"
            required
          />
        </p>
        <p className={classNames.field}>
          <label className={classNames.label} htmlFor="message">
            Message
          </label>
          <textarea
            className={classNames.input}
            name="message"
            id="message"
            rows="5"
            required
          />
        </p>
        <p className={classNames.field}>
          <button className="Button" type="submit">
            Submit
          </button>
        </p>
      </form>
    </Layout>
  );
}

export default Contact;
