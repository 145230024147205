import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import get from 'lodash/get';

import * as types from 'config/types';
import { generateClassNames } from 'lib/utils';
import { useRecommendation } from 'components/RecommendationProvider';
import Item from 'components/Item';
import Package from 'components/Package';

function Recommendation(props = {}) {
  const [collapsed, setCollapsed] = useState(props.collapsedByDefault);
  const { data } = useRecommendation();
  const itemListClassNames = generateClassNames('ItemList', ['item']);
  const parseNumber = number =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (
    <>
      <Package
        collapsed={collapsed}
        collapsible={props.collapsible}
        onCollapse={() => setCollapsed(!collapsed)}
      />
      {!collapsed && (
        <ul className={itemListClassNames.root}>
          <Item
            label="Version"
            dataKey={types.VERSION}
            description="A 1.0.0+ version number indicates maturation."
          >
            {version => version}
          </Item>
          <Item
            label="Latest Release"
            dataKey={types.LATEST_RELEASE}
            description="Frequent releases means the package is maintained."
          >
            {release => format(release, 'MM/dd/yyyy')}
          </Item>
          <Item
            label="Latest Commit"
            dataKey={types.LATEST_COMMIT}
            description="Frequent commits means the package is regularly worked on."
          >
            {commit => format(commit, 'MM/dd/yyyy')}
          </Item>
          <Item
            label="Weekly Downloads"
            dataKey={types.WEEKLY_DOWNLOADS}
            description="Weekly downloads indicates the package is being used by the community."
          >
            {downloads => parseNumber(downloads)}
          </Item>
          <Item
            label="Contributors"
            dataKey={types.CONTRIBUTOR_COUNT}
            description="Multiple contributors means there is a open-source community behind it."
          >
            {contributors => (contributors === 100 ? '100+' : contributors)}
          </Item>
          <Item
            label="Dependencies"
            dataKey={types.DEPENDENCIES}
            description="A lower number of dependencies means less reliance on other packages."
          >
            {dependencies => dependencies}
          </Item>
          <Item
            label="Issues &amp; PRs Ratio"
            dataKey={types.ISSUES_AND_PULLS_RATIO}
            description="A lower ratio means more issues are closed and PRs merged than opened."
          >
            {issuesAndPullsRatio => {
              if (
                isNaN(issuesAndPullsRatio.issuesRatio) ||
                isNaN(issuesAndPullsRatio.pullsRatio)
              ) {
                return '?';
              }
              return `${issuesAndPullsRatio.issuesRatio} / ${issuesAndPullsRatio.pullsRatio}`;
            }}
          </Item>
          <li
            className={itemListClassNames.item}
            data-tip="Package size impacts performance."
          >
            <a
              href={get(data, 'links.bundlephobia')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Verify the package size on bundlephobia.com
            </a>
          </li>
        </ul>
      )}
    </>
  );
}

Recommendation.propTypes = {
  collapsedByDefault: PropTypes.bool,
  collapsible: PropTypes.bool,
};

Recommendation.defaultProps = {
  collapsedByDefault: false,
  collapsible: false,
};

export default Recommendation;
