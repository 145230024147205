import React from 'react';

import { useDocumentTitle } from 'lib/hooks';
import Layout from 'components/Layout';

function Thanks(props = {}) {
  useDocumentTitle('Thanks! - Should I Use?');

  return (
    <Layout>
      <h2 className="PageSubtitle PageSubtitle--s">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="U-c-primary U-mr-s"
        >
          <path d="M20 6L9 17l-5-5" />
        </svg>
        Thank you! We&apos;ll be in touch.
      </h2>
      <p className="U-c-subdued U-ta-center">
        Click the big &quot;Should I Use?&quot; text above to head back home.
      </p>
    </Layout>
  );
}

export default Thanks;
