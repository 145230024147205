import { gql } from 'apollo-boost';

export const GITHUB_BASE_BRANCH_QUERY = gql`
  query repository($name: String!, $owner: String!) {
    repository(name: $name, owner: $owner) {
      id
      defaultBranchRef {
        name
      }
    }
  }
`;

export const GITHUB_REPO_INFO_QUERY = gql`
  query search($query: String!) {
    search(query: $query, type: REPOSITORY, first: 1) {
      nodes {
        ... on RepositoryInfo {
          nameWithOwner
        }
      }
    }
  }
`;

export const GITHUB_QUERY = gql`
  query repository($name: String!, $owner: String!, $branch: String!) {
    repository(name: $name, owner: $owner) {
      id
      name
      releases(last: 1) {
        nodes {
          id
          publishedAt
        }
      }
      issues(last: 100, orderBy: { field: UPDATED_AT, direction: ASC }) {
        nodes {
          id
          title
          state
          createdAt
          closedAt
        }
      }
      pullRequests(last: 100, orderBy: { field: UPDATED_AT, direction: ASC }) {
        nodes {
          id
          title
          state
          createdAt
          closedAt
          mergedAt
        }
      }
      refs(
        refPrefix: "refs/tags/"
        first: 1
        orderBy: { field: TAG_COMMIT_DATE, direction: DESC }
      ) {
        edges {
          node {
            name
            target {
              ... on Tag {
                tagger {
                  date
                }
              }
            }
          }
        }
      }
      object(expression: $branch) {
        ... on Commit {
          history(first: 1) {
            edges {
              node {
                committedDate
              }
            }
          }
        }
      }
    }
  }
`;
