import React from 'react';

function NPMLogo(props = {}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
    >
      <path d="M0 16V0h16v16zM3 3v10h5V5h3v8h2V3z" fill="#c12127" />
      <path d="M3 3h10v10h-2V5H8v8H3z" fill="#fff" />
    </svg>
  );
}

export default NPMLogo;
