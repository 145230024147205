import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReachTooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';

const Tooltip = forwardRef((props = {}, ref) => {
  return (
    <ReachTooltip {...props} className="Tooltip">
      <div ref={ref}>{props.children}</div>
    </ReachTooltip>
  );
});

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  label: PropTypes.string.isRequired,
};

export default Tooltip;
