import { useReducer } from 'react';
import get from 'lodash/get';

const initialState = {
  inputValue: 'Choose a package.json file...',
  packages: [],
  status: 'idle',
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'INIT': {
      return {
        ...state,
        status: 'loading',
      };
    }
    case 'SUCCESS': {
      return {
        ...state,
        packages: payload,
        status: 'complete',
      };
    }
    case 'ERROR': {
      return {
        ...state,
        status: 'error',
      };
    }
    case 'SET_FILE': {
      return {
        ...state,
        inputValue: payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function usePackageParser() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { inputValue, packages, status } = state;
  const ready = status === 'complete' && packages.length > 0;

  const isValidFile = file =>
    file.name === 'package.json' && file.type === 'application/json';

  function handleUpload(event) {
    dispatch({ type: 'INIT' });
    const reader = new FileReader();

    reader.addEventListener('load', e => {
      const data = get(reader, 'result');
      const json = JSON.parse(data);
      const dependencies = Object.keys(json.dependencies || {});
      const devDependencies = Object.keys(json.devDependencies || {});
      const _packages = [...dependencies, ...devDependencies];
      dispatch({ type: 'SUCCESS', payload: _packages });
    });

    const file = get(event, 'target.files[0]');
    if (!isValidFile(file)) return dispatch({ type: 'ERROR' });
    dispatch({ type: 'SET_FILE', payload: file.name });

    reader.readAsText(file);
  }

  return { handleUpload, packages, status, ready, inputValue };
}

export default usePackageParser;
