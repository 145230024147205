import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { generateClassNames } from 'lib/utils';
import { useRecommendation } from 'components/RecommendationProvider';
import GitHubLogo from 'components/GitHubLogo';
import NPMLogo from 'components/NPMLogo';
import PackageLink from 'components/PackageLink';

function Package(props = {}) {
  const { data, recommended } = useRecommendation();
  const packageClassNames = generateClassNames('Package', [
    'title',
    'icon',
    'content',
    'description',
    'links',
    'toggle',
  ]);

  function handleToggleClick(event) {
    event.preventDefault();
    props.onCollapse && props.onCollapse();
  }

  return (
    <div className={packageClassNames.root} data-collapsed={props.collapsed}>
      {recommended ? (
        <b
          className={`${packageClassNames.icon} ${packageClassNames.icon}--yes`}
          data-testid="Recommend-yes"
        >
          <Icon>
            <path d="M9 11l3 3L22 4" />
            <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11" />
          </Icon>
        </b>
      ) : (
        <b
          className={`${packageClassNames.icon} ${packageClassNames.icon}--no`}
          data-testid="Recommend-no"
        >
          <Icon>
            <path d="M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2zM12 8v4M12 16h.01" />
          </Icon>
        </b>
      )}
      <div className={packageClassNames.content}>
        <h1 className={packageClassNames.title}>
          <a
            href={get(data, 'links.root')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {decodeURIComponent(get(data, 'name'))}
          </a>
        </h1>
        <p className={packageClassNames.description}>
          {get(
            data,
            'description',
            'There is no description available for this package.'
          )}
        </p>
        {props.collapsible && (
          <button
            className={`ButtonLink ${packageClassNames.toggle}`}
            onClick={handleToggleClick}
          >
            {props.collapsed ? 'View' : 'Hide'} details
          </button>
        )}
        <ul className={packageClassNames.links}>
          {get(data, 'links.github') && (
            <li>
              <PackageLink
                href={get(data, 'links.github')}
                title="View repo on GitHub"
                className={`${packageClassNames.links}-item`}
              >
                <GitHubLogo />
              </PackageLink>
            </li>
          )}
          {get(data, 'links.npm') && (
            <li>
              <PackageLink
                href={get(data, 'links.npm')}
                title="View package on NPM"
                className={`${packageClassNames.links}-item`}
              >
                <NPMLogo />
              </PackageLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

Package.propTypes = {
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
};

function Icon({ children }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {children}
    </svg>
  );
}

Icon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export default Package;
