function getGHRepoUrl(urlString) {
  if (!urlString) return null;
  const match = urlString.match(/https:\/\/.*github.com\/(.*)\/([^#]*)/i);
  if (!match) return null;
  const [url, owner, repo] = match;
  if (!url && owner && repo) return `https://github.com/${owner}/${repo}`;
  if (!url && (!owner || !repo)) return null;
  return { url, owner, repo };
}

export default getGHRepoUrl;
