import parseNPMUrl from './parseNPMUrl';
import parseRepo from './parseRepo';

const parseNPMData = npmData => {
  const parsedUrl = parseNPMUrl(npmData);
  const parsedRepo = parseRepo(parsedUrl);
  return parsedRepo;
};

export default parseNPMData;
