import get from 'lodash/get';

import isVersionGreaterOrEqualTo from './isVersionGreaterOrEqualTo';
import normalizeVersion from './normalizeVersion';

function getLatestVersion(npmData, ghTag) {
  const npmVersion = get(npmData, 'collected.metadata.version');
  const ghVersion = get(ghTag, 'name');

  if (npmVersion && !ghVersion) return normalizeVersion(npmVersion);
  if (!npmVersion && ghVersion) return normalizeVersion(npmVersion);

  if (isVersionGreaterOrEqualTo(npmVersion, ghVersion)) {
    return normalizeVersion(npmVersion);
  }

  return normalizeVersion(ghVersion);
}

export default getLatestVersion;
