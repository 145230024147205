import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/react-hooks';
import { useMachine } from '@xstate/react';

import client from 'config/apollo';
import { machine } from 'lib';
import { useDocumentTitle, usePackageParser, useReport } from 'lib/hooks';
import Content from 'components/Content';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import RecommendationProvider from 'components/RecommendationProvider';
import UploadIcon from 'components/UploadIcon';
import { sendEvent } from 'lib/utils';

function Report(props = {}) {
  const {
    handleUpload,
    packages,
    status,
    ready,
    inputValue,
  } = usePackageParser();
  const [
    { complete, loadingText },
    { handleComplete, setPackages },
  ] = useReport(packages);
  useDocumentTitle('Report - Should I Use?');

  useEffect(() => {
    const sendUploadEvent = packages => {
      sendEvent('FS', {
        eventName: 'Selected Package',
        eventProperties: {
          packages: packages.join(', '),
        },
      });
      sendEvent('GA', {
        category: 'Recommendation',
        action: 'Selected a Package',
        label: packages.join(', '),
      });
    };

    if (ready) {
      setPackages(packages);
      sendUploadEvent(packages);
    }
  }, [ready, packages, setPackages]);

  const showLoader = ready && !complete;
  const showPackages = ready;

  return (
    <ApolloProvider client={client}>
      <Layout>
        <div className="U-mb-l">
          {status === 'idle' && <BlankSlate />}
          <label className="FileInput">
            <input
              type="file"
              id="file"
              aria-label="File browser"
              onChange={handleUpload}
            />
            <span className="FileInput-custom" data-value={inputValue}></span>
          </label>
        </div>
        {status === 'error' && (
          <p className="U-ta-center">
            Sorry, there was an error. Make sure you choose a package.json file.
          </p>
        )}
        {showLoader && <Loader text={loadingText} />}
        <div className="Report" data-complete={complete}>
          {showPackages &&
            packages.map((pkg, idx) => (
              <Package
                key={`${pkg}-${idx}`}
                pkg={pkg}
                onComplete={handleComplete}
              />
            ))}
        </div>
        {status === 'idle' && <Footer />}
      </Layout>
    </ApolloProvider>
  );
}

function BlankSlate() {
  return (
    <div className="U-mb-l U-mw-25 U-mx-auto U-ta-center">
      <div className="U-mb-s">
        <UploadIcon />
      </div>
      <p className="U-c-subdued">
        Upload a valid package.json file and we will automatically run a report
        on all packages at one time.
      </p>
    </div>
  );
}

function Package(props = {}) {
  const [current, send] = useMachine(machine);
  const { pkg, onComplete } = props;

  useEffect(() => {
    send('SELECT_MULTIPLE', { data: pkg });
  }, [send, pkg]);

  useEffect(() => {
    const complete =
      current.matches('recommend.success') ||
      current.matches('recommend.error');
    if (complete) onComplete && onComplete(pkg);
  }, [current, onComplete, pkg]);

  return (
    <RecommendationProvider recommendation={current.context.recommendation}>
      <div className="U-mb-s">
        <Content
          current={current}
          send={send}
          showLoader={false}
          showIdle={false}
          showCollapsedContent={true}
          collapsible={true}
        />
      </div>
    </RecommendationProvider>
  );
}

Package.propTypes = {
  onComplete: PropTypes.func.isRequired,
  pkg: PropTypes.string.isRequired,
};

export default Report;
