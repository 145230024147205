import * as Sentry from '@sentry/browser';

function handleResponseErrors(error, options = {}) {
  Sentry.configureScope(scope => {
    Object.keys(error).forEach(key => {
      scope.setExtra(key, error[key]);
    });
  });

  function message(withPkg, withoutPkg) {
    const pkg = options?.pkg;
    if (pkg) return withPkg.replace(/\{pkg\}/, `'${pkg}'`);
    return withoutPkg;
  }

  const defaultErrorMessage = message(
    'Sorry. An error occurred with {pkg}.',
    'Sorry. An error occurred.'
  );

  if (!error.status) {
    if (error.message) {
      Sentry.captureMessage(
        `Error caught in handleResponseErrors: ${error.message}`
      );

      return `Error: ${error.message}`;
    }

    Sentry.captureMessage(
      `Error caught in handleResponseErrors: ${defaultErrorMessage}`
    );

    return defaultErrorMessage;
  }

  switch (error.status) {
    case 404: {
      Sentry.captureMessage('handleResponseErrors 404');
      return message(
        'Sorry. We could not find {pkg}.',
        'Sorry. We could not find that package.'
      );
    }
    case 403: {
      Sentry.captureMessage('handleResponseErrors 403');
      return message(
        'Sorry. There was an error with the recommendation for {pkg}.',
        'Sorry. There was an error with the recommendation.'
      );
    }
    default: {
      Sentry.captureMessage(`handleResponseErrors ${error.status}`);
      return defaultErrorMessage;
    }
  }
}

export default handleResponseErrors;
