import get from 'lodash/get';

import { getGHRepoUrl } from './';

function getPackageUrl({ repo, owner, npmData }) {
  const npmLink =
    get(npmData, 'collected.metadata.links.repository') ||
    get(npmData, 'collected.metadata.links.homepage') ||
    get(npmData, 'collected.metadata.links.npm');
  const ghRepoUrl = getGHRepoUrl(npmLink);
  const url = ghRepoUrl ? ghRepoUrl : npmLink;
  return url;
}

export default getPackageUrl;
