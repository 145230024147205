import get from 'lodash/get';

function parseNPMUrl(npmData) {
  const url =
    get(npmData, 'collected.metadata.links.repository') ||
    get(npmData, 'collected.metadata.links.homepage') ||
    get(npmData, 'collected.metadata.repository.url');

  if (!url) return null;

  const parsedUrl = url.replace(/\.git$/, '').replace(/git:\/\//, 'https://');

  return parsedUrl;
}

export default parseNPMUrl;
