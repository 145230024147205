import getGHRepoUrl from './getGHRepoUrl';

const parseRepo = url => {
  if (!url) return null;
  const repoUrl = getGHRepoUrl(url);
  if (!repoUrl) return null;
  const { owner, repo } = repoUrl;
  return { owner, repo };
};

export default parseRepo;
