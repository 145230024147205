import get from 'lodash/get';

function getDescription(npmData) {
  const _description = get(npmData, 'collected.metadata.description');
  if (!_description) return null;

  const startsWithBuildImage = _description.match(/\[!\[.*\]\(.*\)/g);
  if (startsWithBuildImage) return null;

  return _description;
}

export default getDescription;
