import get from 'lodash/get';

export const parseGHBranch = ghData => {
  return get(ghData, 'data.repository.defaultBranchRef.name', 'master');
};

const parseGHData = ghData => {
  const repoData = get(ghData, 'data.repository');
  return {
    issues: get(repoData, 'issues.nodes', null),
    pulls: get(repoData, 'pullRequests.nodes', null),
    release: get(repoData, 'releases.nodes[0]'),
    commit: get(repoData, 'object.history.edges[0].node'),
    tag: {
      name: get(repoData, 'refs.edges[0].node.name'),
      date: get(repoData, 'refs.edges[0].node.target.tagger.date'),
    },
  };
};

export default parseGHData;
