import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { generateClassNames } from 'lib/utils';
import { useRecommendation } from 'components/RecommendationProvider';
import Tooltip from 'components/Tooltip';

function Item(props = {}) {
  const { data, recommendations } = useRecommendation();
  const classNames = generateClassNames('ItemList-item', [
    'label',
    'value',
    'reason',
  ]);
  const datum = get(data, props.dataKey);
  if (!datum) {
    return (
      <li className={classNames.root} data-testid="Item-noData">
        <Tooltip label="We could not retrieve this data, unfortunately.">
          <>
            <span className={classNames.label}>{props.label}</span>
            <strong className={classNames.value}>N/A</strong>
            <p className={`${classNames.reason}`}>No data present</p>
          </>
        </Tooltip>
      </li>
    );
  }
  const recommendation = recommendations[props.dataKey];
  const showNotApplicableString = recommendation.reason.match(
    /not enough data/i
  );
  return (
    <li className={classNames.root}>
      <Tooltip label={props.description}>
        <>
          <span className={classNames.label}>{props.label}</span>
          <strong
            className={classNames.value}
            data-testid={`${props.dataKey}-value`}
          >
            {showNotApplicableString
              ? 'N/A'
              : props.children && props.children(datum)}
          </strong>
          <p
            className={`${classNames.reason} ${classNames.reason}--${
              recommendation.recommended ? 'yes' : 'no'
            }`}
            data-testid={`${props.dataKey}-reason`}
          >
            {recommendation.reason}
          </p>
        </>
      </Tooltip>
    </li>
  );
}

Item.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  dataKey: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
};

export default Item;
