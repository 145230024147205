import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';

function cleanData(data) {
  let cleanedData = {};

  Object.keys(data).map(key => {
    const value = data[key];
    const plainObject = isPlainObject(value);
    const notNil = !isNil(value);
    const notNilNotPlainObject = notNil && !plainObject;
    const notNilPlainObject = notNil && plainObject;

    if (notNilNotPlainObject) {
      // This takes care of `Invalid Date` values.
      if (typeof value === 'object' && isNaN(value)) {
        return (cleanedData[key] = null);
      }
      return (cleanedData[key] = data[key]);
    }

    if (notNilPlainObject) {
      // If all of the object's values are nil, let's just return
      // `null` on the root object without going to the individual
      // values within the object.
      if (Object.values(value).every(v => isNil(v))) {
        return (cleanedData[key] = null);
      }

      // Not all values in the object are nil, so we're going to
      // loop through the object's values and individually set them
      // to `null` before returning the object.
      const nestedCleanedObject = {};
      Object.keys(value).forEach(k => {
        const _value = data[key][k];
        if (isNil(_value)) {
          nestedCleanedObject[k] = null;
        } else {
          nestedCleanedObject[k] = _value;
        }
      });
      return (cleanedData[key] = nestedCleanedObject);
    }

    return (cleanedData[key] = null);
  });

  return cleanedData;
}

export default cleanData;
