import React from 'react';

function About(props = {}) {
  return (
    <div className="About" data-testid="About">
      <p>
        There are over a million JavaScript packages you can use in your
        project. <strong>Should I Use?</strong> helps you evaluate with a
        baseline of criteria:
      </p>
      <ul>
        <li>
          Is it <strong>at least a v1.0.0</strong>?
        </li>
        <li>
          Has there been <strong>a new release in the past six months</strong>?
        </li>
        <li>
          Has there been{' '}
          <strong>a commit to the repo in the past six months</strong>?
        </li>
        <li>
          Are there <strong>more than 100 weekly downloads</strong>?
        </li>
        <li>
          Are there <strong>more than 10 contributors</strong>?
        </li>
        <li>
          Are there <strong>less than 40 dependencies</strong>?
        </li>
        <li>
          Is{' '}
          <strong>
            the ratio of issues closed/open and PRs opened/merged less than 2
          </strong>
          ?
        </li>
        <li>Is the size of the package within our performance budget?</li>
      </ul>
    </div>
  );
}

export default About;
