import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Router as ReactRouter, Route, Switch } from 'react-router-dom';

import { isProduction } from 'config/env';
import Contact from 'screens/Contact';
import Main from 'screens/Main';
import Report from 'screens/Report';
import Thanks from 'screens/Thanks';

const history = createBrowserHistory();

function trackPage(page) {
  if (isProduction) {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }
}

history.listen(location => {
  const page = location.pathname;
  trackPage(page);
});

function Router() {
  useEffect(() => {
    const page = window?.location?.pathname;
    trackPage(page);
  }, []);

  return (
    <ReactRouter history={history}>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/report" component={Report} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/contact/success" component={Thanks} />
      </Switch>
    </ReactRouter>
  );
}

export default Router;
