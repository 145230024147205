import { assign } from 'xstate';

import { setUrlParams } from 'lib/utils';
import { Recommendation } from 'lib';

const actions = initialContext => ({
  type: assign({
    query: (_, event) => event.query,
  }),
  select: assign({
    query: (_, event) => event.data,
    loadingText: 'Getting NPM data...',
    results: [],
  }),
  setUrlParams: assign((context, event) => {
    const { query } = context;
    setUrlParams(query);
  }),
  clear: assign((context, event) => {
    setUrlParams('', true);
    return initialContext;
  }),
  clearResults: assign({
    results: initialContext.results,
  }),
  setRecommendation: assign({
    recommendation: (context, event) => {
      const { data, criteria } = context;
      const recommendation = new Recommendation();
      const _data = recommendation.normalizeData(data);
      if (criteria) recommendation.update(criteria);
      const _recommendation = recommendation.getRecommendation();
      return { data: _data, ..._recommendation };
    },
  }),
});

export default actions;
