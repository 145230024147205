import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip';

function PackageLink(props = {}) {
  return (
    <Tooltip label={props.title}>
      <a
        href={props.href}
        title={props.title}
        className={props.className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    </Tooltip>
  );
}

PackageLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
};

export default PackageLink;
