import React from 'react';

function DiLogo(props = {}) {
  return (
    <a href="https://differential.com" className="DiLogo">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-2.77778 32.7778H32.7778V-2.77778H-2.77778V32.7778Z"
            fill="#414141"
          />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="9"
            y="7"
            width="12"
            height="16"
          >
            <path
              d="M16.2678 18.7556C16.2678 19.7061 15.9344 20.5194 15.2678 21.1944C14.6022 21.8694 13.7978 22.2072 12.8561 22.2072H9.44444V7.77778H12.8556C13.8056 7.77778 14.6111 8.11556 15.2739 8.79056C15.9361 9.46556 16.2678 10.2744 16.2678 11.2183V18.7556ZM11.6156 20.0556H12.925C13.2694 20.0556 13.5606 19.9306 13.7978 19.6817C14.0356 19.4317 14.1539 19.1389 14.1539 18.8011V11.2528C14.1539 10.9078 14.0333 10.6122 13.7922 10.3667C13.5672 10.1283 13.2528 9.995 12.925 9.99827H11.6156V20.0556ZM18.0428 22.2072H20.2139V12.7778H18.0428V22.2072Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.66667 24.985H22.9917V5H6.66667V24.985Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}

export default DiLogo;
