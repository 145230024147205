import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

function RecommendationProvider(props = {}) {
  const recommendation = props.recommendation;
  const state = useMemo(() => ({ ...recommendation }), [recommendation]);
  return <Context.Provider value={state}>{props.children}</Context.Provider>;
}

// const state = useRecommendation();
function useRecommendation() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useProvider must be used within a Provider`);
  }
  return context;
}

RecommendationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  recommendation: PropTypes.object,
};

export { RecommendationProvider as default, useRecommendation };
